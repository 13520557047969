import React, { Component } from 'react';
import { Query, Mutation } from '@apollo/react-components';
import { Link } from 'react-router-dom';
import Loading from '../Loading';
import {
  ArrowLeftOutlined,
  PlusOutlined,
  UploadOutlined,
  MoreOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';
import { Button, message, Table, Modal, Tooltip, Popover } from 'antd/es';
import FirmDetailsForm from '../Forms/FirmDetailsForm';
import AddFirmMemberForm from '../Forms/AddFirmMemberForm';
import UpdateFirmMemberForm from '../Forms/UpdateFirmMemberForm';
import { ReactComponent as IconAddFirmMember } from '../../assets/images/icon-add-firm-member.svg';
import { ReactComponent as IconModalClose } from '../../assets/images/icon-modal-close.svg';
import { GET_FIRM_DETAILS } from '../../graphql/firms/get-firm-details';
import { CHANGE_FIRM_MEMBER_STATUS } from '../../graphql/firms/change-firm-member-status';
import { UPDATE_FIRM_LOGO } from '../../graphql/firms/update-firm-logo';
import { LIST_CLIENT_ACCOUNTS_FOR_FIRM } from '../../graphql/firms/list-client-accounts-for-firm';
import { LIST_FIRM_MEMBERS } from '../../graphql/firms/list-firm-members';
import { REMOVE_FIRM_MEMBER } from '../../graphql/firms/remove-firm-member';
import { RESEND_PRO_INVITE } from '../../graphql/firms/resend-pro-invite';
import tpLogoIconOnly from '../../assets/images/transparently-logo-icon-only.svg';
import { BP_LARGE } from '../../consts';
import './FirmDetails.scss';

class FirmDetails extends Component {
  constructor() {
    super();
    this.state = {
      addFirmMemberModalVisible: false,
      updateFirmMemberModalVisible: false,
      firmMemberToUpdate: '',
    };
  }

  addFirmMemberModalVisible = () => {
    this.setState((prevState) => {
      return { addFirmMemberModalVisible: !prevState.addFirmMemberModalVisible };
    });
  };

  updateFirmMemberModalVisible = () => {
    this.setState((prevState) => {
      return { updateFirmMemberModalVisible: !prevState.updateFirmMemberModalVisible };
    });
  };

  beforeFirmLogoUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error('Image must smaller than 2MB!');
    }
    return isJpgOrPng && isLt2M;
  };

  render() {
    const firmMemberTable = (firmData, firmMembersData) => [
      {
        title: <img src={tpLogoIconOnly} alt="" />,
        dataIndex: ['professional', 'pictureUrl'],
        width: '80px',
        render: (image) => (
          <img src={`${image}`} alt="" height="36" width="36" className="avatar avatar--in-table" />
        ),
        align: 'center',
      },
      {
        title: (
          <>
            Preferred /<br /> first name
          </>
        ),
        dataIndex: ['professional', 'preferredName'],
        sorter: (a, b) => {
          var nameA = !a.professional.preferredName
            ? ''
            : a.professional.preferredName.toUpperCase();
          var nameB = !b.professional.preferredName
            ? ''
            : b.professional.preferredName.toUpperCase();
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        },
        sortDirections: ['ascend', 'descend'],
      },
      {
        title: 'Last name',
        dataIndex: ['professional', 'lastName'],
        sorter: (a, b) => {
          var nameA = !a.professional.lastName ? '' : a.professional.lastName.toUpperCase();
          var nameB = !b.professional.lastName ? '' : b.professional.lastName.toUpperCase();
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        },
        sortDirections: ['ascend', 'descend'],
        defaultSortOrder: 'ascend',
      },
      {
        title: 'Email address',
        dataIndex: ['professional', 'emailAddress'],
        sorter: (a, b) => {
          var nameA = !a.professional.emailAddress ? '' : a.professional.emailAddress.toUpperCase();
          var nameB = !b.professional.emailAddress ? '' : b.professional.emailAddress.toUpperCase();
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        },
        sortDirections: ['ascend', 'descend'],
      },
      {
        title: 'tp member ID',
        dataIndex: ['professional', 'referenceNumber'],
        sorter: (a, b) => {
          var nameA = !a.professional.referenceNumber
            ? ''
            : a.professional.referenceNumber.toUpperCase();
          var nameB = !b.professional.referenceNumber
            ? ''
            : b.professional.referenceNumber.toUpperCase();
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        },
        sortDirections: ['ascend', 'descend'],
      },
      {
        title: 'SRA ID number',
        dataIndex: ['professional', 'sraNumber'],
        render: (text) => (text ? text : 'n/a'),
        sorter: (a, b) => {
          var nameA = !a.professional.sraNumber ? '' : a.professional.sraNumber.toUpperCase();
          var nameB = !b.professional.sraNumber ? '' : b.professional.sraNumber.toUpperCase();
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        },
        sortDirections: ['ascend', 'descend'],
      },
      {
        title: 'Paralegal number',
        dataIndex: ['professional', 'paralegalNumber'],
        render: (text) => (text ? text : 'n/a'),
        sorter: (a, b) => {
          var nameA = !a.professional.paralegalNumber
            ? ''
            : a.professional.paralegalNumber.toUpperCase();
          var nameB = !b.professional.paralegalNumber
            ? ''
            : b.professional.paralegalNumber.toUpperCase();
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        },
        sortDirections: ['ascend', 'descend'],
      },
      {
        title: 'Employee number',
        dataIndex: 'employeeNumber',
        sorter: (a, b) => {
          var nameA = !a.professional.employeeNumber
            ? ''
            : a.professional.employeeNumber.toUpperCase();
          var nameB = !b.professional.employeeNumber
            ? ''
            : b.professional.employeeNumber.toUpperCase();
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        },
        sortDirections: ['ascend', 'descend'],
      },
      {
        title: 'Type',
        dataIndex: ['professional', 'type'],
        render: (type) => type.toLowerCase(),
        filters:
          firmData?.firmProfessionalTypes?.enumValues?.map((type) => ({
            text: type.name.replace(/_/g, ' ').toLowerCase(),
            value: type.name,
          })) ?? [],
        onFilter: (value, record) => record.professional.type === value,
        filterMultiple: true,
      },
      {
        title: 'Office',
        dataIndex: 'office',
        sorter: (a, b) => {
          var nameA = !a.office ? '' : a.office.toUpperCase();
          var nameB = !b.office ? '' : b.office.toUpperCase();
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        },
        sortDirections: ['ascend', 'descend'],
      },
      {
        title: 'Job title',
        dataIndex: 'jobTitle',
        sorter: (a, b) => {
          var nameA = !a.jobTitle ? '' : a.jobTitle.toUpperCase();
          var nameB = !b.jobTitle ? '' : b.jobTitle.toUpperCase();
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        },
        sortDirections: ['ascend', 'descend'],
      },
      {
        title: 'Role',
        dataIndex: 'role',
        render: (text) => {
          return text.toLowerCase();
        },
        filters:
          firmData?.firmRoles?.enumValues?.map((role) => ({
            text: role.name.replace(/_/g, ' ').toLowerCase(),
            value: role.name,
          })) ?? [],
        onFilter: (value, record) => record.role === value,
        filterMultiple: true,
      },
      {
        title: 'status',
        dataIndex: 'status',
        render: (status) => status.replace(/_/g, ' ').toLowerCase(),
        filters: [
          {
            text: 'Active',
            value: true,
          },
          {
            text: 'Inactive',
            value: false,
          },
        ],
        onFilter: (value, record) => record.isActive === value,
        filterMultiple: true,
      },

      {
        title: (
          <>
            User account <br /> status
          </>
        ),
        dataIndex: ['professional', 'status'],
        render: (status) => status.replace(/_/g, ' ').toLowerCase(),
        filters:
          firmData?.firmProfessionalStatuses?.enumValues?.map((status) => ({
            text: status.name.replace(/_/g, ' ').toLowerCase(),
            value: status.name,
          })) ?? [],
        onFilter: (value, record) => record.professional.status === value,
        filterMultiple: true,
      },
      {
        title: '',
        dataIndex: ['professional', 'status'],
        render: (text, record, index) => {
          const { professional } = record;

          return (
            <Popover
              trigger="click"
              placement="left"
              onClick={(e) => {
                e.stopPropagation();
              }}
              content={
                <>
                  {/* Activate/deactivate account */}
                  <Mutation
                    mutation={CHANGE_FIRM_MEMBER_STATUS}
                    variables={{
                      input: {
                        firmMemberId: record.id,
                        isActive: !record.isActive,
                      },
                    }}
                    onCompleted={(data) => {
                      const { isActive, professional } = data.changeFirmMemberStatus;
                      message.success(
                        <span className="u-standardcase">
                          {`${professional.preferredName} ${professional.lastName
                            }'s membership was set to
                  ${isActive ? 'active' : 'inactive'}`}
                        </span>,
                        4
                      );
                    }}
                    onError={(data) => {
                      message.error(`Sorry, an error occurred`, 4);
                    }}
                  >
                    {(changeFirmMemberStatus, { loading, error, data }) => {
                      const confirmStatusChange = () => {
                        Modal.confirm({
                          title: `${record.isActive ? 'Deactivate member ' : 'Activate member'}`,
                          icon: <ExclamationCircleOutlined />,
                          content: `Are you sure you want to ${record.isActive ? 'deactivate member ' : 'activate member'
                            } this firm member?`,
                          centered: true,
                          okText: `${record.isActive ? 'Deactivate member ' : 'Activate member'}`,
                          cancelText: 'Cancel',
                          onOk: changeFirmMemberStatus,
                        });
                      };

                      return (
                        <Button
                          type="link"
                          className="p-0"
                          onClick={(e) => {
                            e.stopPropagation();
                            confirmStatusChange();
                          }}
                        >
                          {(record.isActive ? 'Deactivate' : 'Activate') + ' member'}
                        </Button>
                      );
                    }}
                  </Mutation>

                  {/* Resend professional invite email */}
                  {(professional.status === 'INVITED' ||
                    professional.status === 'INVITE_EXPIRED') && (
                      <Mutation
                        mutation={RESEND_PRO_INVITE}
                        refetchQueries={[
                          {
                            query: LIST_FIRM_MEMBERS,
                            variables: { firmId: this.props.match.params.id },
                          },
                        ]}
                        variables={{
                          input: {
                            firmMemberId: record.id,
                          },
                        }}
                        onCompleted={(data) => {
                          message.success('The invite has been sent successfully', 4);
                        }}
                        onError={(data) => {
                          message.error(`Sorry, an error occurred`, 4);
                        }}
                      >
                        {(resendProInvite, { loading, error, data }) => {
                          const confirmPublishStatus = () => {
                            Modal.confirm({
                              title: `Resend invite`,
                              icon: <ExclamationCircleOutlined />,
                              content: `Are you sure you want to resend the invite?`,
                              centered: true,
                              okText: `Resend invite`,
                              cancelText: 'Cancel',
                              onOk: resendProInvite,
                            });
                          };

                          return (
                            <Button
                              type="link"
                              className="p-0"
                              onClick={(e) => {
                                e.stopPropagation();
                                confirmPublishStatus();
                              }}
                            >
                              Resend invite
                            </Button>
                          );
                        }}
                      </Mutation>
                    )}

                  {/* Remove firm member */}
                  <Mutation
                    mutation={REMOVE_FIRM_MEMBER}
                    refetchQueries={[
                      {
                        query: LIST_FIRM_MEMBERS,
                        variables: { firmId: this.props.match.params.id },
                      },
                    ]}
                    variables={{
                      input: {
                        firmMemberId: record.id,
                      },
                    }}
                    onCompleted={(data) => {
                      message.success(
                        <>
                          <span className="u-standardcase">
                            <b>
                              {professional.preferredName} {professional.lastName + ' '}
                            </b>
                          </span>
                          has been successfully removed
                        </>,
                        4
                      );
                    }}
                    onError={(data) => {
                      message.error(`Sorry, an error occurred`, 4);
                    }}
                  >
                    {(removeFirmMember, { loading, error, data }) => {
                      const confirmRemoveFirmMember = () => {
                        Modal.confirm({
                          title: `Remove firm member`,
                          icon: <ExclamationCircleOutlined />,
                          content: (
                            <>
                              Are you sure you want to remove{' '}
                              <span className="u-standardcase">
                                <b>
                                  {professional.preferredName} {record.professional.lastName + ' '}
                                </b>
                                from the firm?
                              </span>
                            </>
                          ),
                          centered: true,
                          okText: `Remove member`,
                          cancelText: 'Cancel',
                          onOk: removeFirmMember,
                        });
                      };

                      return (
                        <Button
                          type="link"
                          className="p-0"
                          onClick={(e) => {
                            e.stopPropagation();
                            confirmRemoveFirmMember();
                          }}
                        >
                          Remove member
                        </Button>
                      );
                    }}
                  </Mutation>
                </>
              }
            >
              <MoreOutlined style={{ fontSize: 20 }} />
            </Popover>
          );
        },
        align: 'right',
        width: 10,
      },
    ];

    const clientListTable = [
      {
        title: <img src={tpLogoIconOnly} alt="" />,
        dataIndex: ['client', 'pictureUrl'],
        width: '80px',
        render: (image) => (
          <img src={`${image}`} alt="" height="36" width="36" className="avatar avatar--in-table" />
        ),
        align: 'center',
      },
      {
        title: (
          <>
            Preferred /<br /> first name
          </>
        ),
        dataIndex: ['client', 'preferredName'],
        sorter: (a, b) => {
          var nameA = !a.client.preferredName ? '' : a.client.preferredName.toUpperCase();
          var nameB = !b.client.preferredName ? '' : b.client.preferredName.toUpperCase();
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        },
        sortDirections: ['ascend', 'descend'],
      },
      {
        title: 'Last name',
        dataIndex: ['client', 'lastName'],
        sorter: (a, b) => {
          var nameA = !a.client.lastName ? '' : a.client.lastName.toUpperCase();
          var nameB = !b.client.lastName ? '' : b.client.lastName.toUpperCase();
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        },
        sortDirections: ['ascend', 'descend'],
      },
      {
        title: 'Email address',
        dataIndex: ['client', 'emailAddress'],
        sorter: (a, b) => {
          var nameA = !a.client.emailAddress ? '' : a.client.emailAddress.toUpperCase();
          var nameB = !b.client.emailAddress ? '' : b.client.emailAddress.toUpperCase();
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        },
        sortDirections: ['ascend', 'descend'],
      },
      {
        title: 'Mobile number',
        dataIndex: ['client', 'mobilePhoneNumber'],
        sorter: (a, b) => {
          var nameA = !a.client.mobilePhoneNumber ? '' : a.client.mobilePhoneNumber.toUpperCase();
          var nameB = !b.client.mobilePhoneNumber ? '' : b.client.mobilePhoneNumber.toUpperCase();
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        },
        sortDirections: ['ascend', 'descend'],
      },
      {
        title: 'Status',
        dataIndex: 'status',
        render: (status) => status.replace(/_/g, ' ').toLowerCase(),
        sorter: (a, b) => {
          var nameA = !a.status ? '' : a.status.toUpperCase();
          var nameB = !b.status ? '' : b.status.toUpperCase();
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        },
        sortDirections: ['ascend', 'descend'],
      },
      {
        title: (
          <>
            User account
            <br /> status
          </>
        ),
        dataIndex: ['client', 'status'],
        render: (status) => status.replace(/_/g, ' ').toLowerCase(),
        sorter: (a, b) => {
          var nameA = !a.status ? '' : a.status.toUpperCase();
          var nameB = !b.status ? '' : b.status.toUpperCase();
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        },
        sortDirections: ['ascend', 'descend'],
      },
    ];

    return (
      <>
        <Query
          query={GET_FIRM_DETAILS}
          variables={{
            firmId: this.props.match.params.id,
          }}
        >
          {({ loading, error, data: firmData }) => {
            if (loading) {
              return <Loading />;
            }

            if (error) return <p>Error :(</p>;

            if (firmData.firm) {
              return (
                <>
                  <Button ghost type="primary" className="back-btn">
                    <Link to="/firms">
                      <ArrowLeftOutlined />
                    </Link>
                  </Button>

                  <div className="u-flex-align-center firm-details">
                    <img
                      src={firmData.firm.logoUrl}
                      className="avatar"
                      alt=""
                      width="65"
                      height="65"
                    />

                    <h1 className="firm-name">
                      {firmData.firm.name}
                      <span className="firm-name__meta">
                        <Tooltip title="tp firm ID">{firmData.firm.referenceNumber}</Tooltip>
                        <Tooltip title="Firm type">
                          {firmData.firm.type.replace(/_/g, ' ').toLowerCase()}
                        </Tooltip>
                      </span>
                    </h1>
                  </div>

                  <Mutation
                    mutation={UPDATE_FIRM_LOGO}
                    onCompleted={(data) => {
                      message.success('Firm logo successfully changed', 4);
                      this.setState({ uploadingFirmLogo: false });
                    }}
                    onError={(data) => {
                      message.error(`Failed to change firm logo`, 4);
                      this.setState({ uploadingFirmLogo: false });
                    }}
                  >
                    {(updateFirmLogo) => (
                      <>
                        <Button type="primary">
                          <label htmlFor="firm-logo-change">
                            <UploadOutlined />
                            <span>
                              {this.state.uploadingFirmLogo
                                ? 'Changing firm logo'
                                : 'Change firm logo'}
                            </span>
                          </label>
                        </Button>
                        <input
                          id="firm-logo-change"
                          type="file"
                          accept=".jpg, .jpeg, .png"
                          style={{ opacity: 0, pointerEvents: 'none' }}
                          onChange={({
                            target: {
                              validity,
                              files: [file],
                            },
                          }) => {
                            const isJpgOrPng =
                              file.type === 'image/jpeg' || file.type === 'image/png';
                            const isLt2M = file.size / 1024 / 1024 < 2;

                            if (!isJpgOrPng) {
                              message.error('Only JPG and PNG files are supported');
                            } else if (!isLt2M) {
                              message.error('Maximum image size is 2MB');
                            } else if (validity.valid) {
                              this.setState({ uploadingFirmLogo: true });
                              updateFirmLogo({
                                variables: {
                                  input: {
                                    id: this.props.match.params.id,
                                    logo: file,
                                  },
                                },
                              });
                            }
                          }}
                        />
                      </>
                    )}
                  </Mutation>

                  <FirmDetailsForm data={firmData} />

                  <div id="firm-members" className="firm-members-header-wrapper">
                    <div className="firm-members-header">
                      <h2 className="firm-members-header__heading">Firm members</h2>
                      <span className="firm-members-header__desc">
                        Showing firm members of{' '}
                        <span className="u-standardcase">{firmData.firm.name}</span>
                      </span>
                    </div>

                    <div className="firm-members-stats">
                      <div className="firm-members-stats__item">
                        <span className="firm-members-stats__counter">
                          {firmData.firm.occupiedSeatCount}
                        </span>
                        Licences is use
                      </div>
                      <div className="firm-members-stats__item">
                        <span className="firm-members-stats__counter">
                          {firmData.firm.vacantSeatCount}
                        </span>
                        Licences available
                      </div>
                    </div>
                  </div>
                  <div className="firm-members__add-member">
                    <Button
                      type="primary"
                      className="firm-members__add-member-btn"
                      onClick={this.addFirmMemberModalVisible}
                      disabled={firmData.firm.vacantSeatCount < 1}
                    >
                      <PlusOutlined />
                      Add firm member
                    </Button>
                  </div>

                  <Query
                    query={LIST_FIRM_MEMBERS}
                    variables={{
                      firmId: this.props.match.params.id,
                    }}
                    onCompleted={() => {
                      var hash = window.location.hash;
                      var firmMembersOffset = document.getElementById('firm-members').offsetTop;
                      var layout = document.querySelector('.ant-layout-content');
                      if (hash === '#firm-members') {
                        if (window.innerWidth < BP_LARGE) {
                          layout.scrollTo({ top: firmMembersOffset - 50, behavior: 'smooth' });
                        } else {
                          layout.scrollTo({ top: firmMembersOffset + 20, behavior: 'smooth' });
                        }
                      }
                    }}
                  >
                    {({ loading, error, data: firmMembersData }) => {
                      if (loading) {
                        return (
                          <Table
                            loading={{
                              spinning: loading,
                              indicator: <Loading small />,
                            }}
                            columns={firmMemberTable()}
                          />
                        );
                      }

                      if (error) return <p>Error :(</p>;

                      /* Show table of firm members if there are members, if not show that there aren't any members */
                      return firmMembersData.firmMembers.length > 0 ? (
                        <Table
                          className="firm-members-table"
                          dataSource={firmMembersData.firmMembers}
                          columns={firmMemberTable(firmData, firmMembersData)}
                          rowKey={(record) => record.id}
                          pagination={{ pageSize: 10, align: 'center' }}
                          onRow={(record) => {
                            return {
                              onClick: () => {
                                this.setState({ firmMemberToUpdate: record.id });
                                this.updateFirmMemberModalVisible();
                              },
                            };
                          }}
                        />
                      ) : (
                        <div className="firm-members-none">
                          <IconAddFirmMember height="30" className="firm-members-none__icon" />
                          No members have been added to this firm yet,{' '}
                          <button
                            className="firm-members-none__add-member-btn"
                            onClick={this.addFirmMemberModalVisible}
                          >
                            add one now?
                          </button>
                        </div>
                      );
                    }}
                  </Query>

                  <h2 id="firm-clients" className="header mt-40">
                    Client list
                  </h2>

                  <Query
                    query={LIST_CLIENT_ACCOUNTS_FOR_FIRM}
                    variables={{
                      firmId: this.props.match.params.id,
                    }}
                    onCompleted={() => {
                      var hash = window.location.hash;
                      var firmClientsOffset = document.getElementById('firm-clients').offsetTop;
                      var layout = document.querySelector('.ant-layout-content');
                      if (hash === '#firm-clients') {
                        if (window.innerWidth < BP_LARGE) {
                          layout.scrollTo({ top: firmClientsOffset - 50, behavior: 'smooth' });
                        } else {
                          layout.scrollTo({ top: firmClientsOffset + 20, behavior: 'smooth' });
                        }
                      }
                    }}
                  >
                    {({ loading, error, data }) => {
                      if (loading) {
                        return (
                          <Table
                            loading={{
                              spinning: loading,
                              indicator: <Loading small />,
                            }}
                            columns={clientListTable}
                          />
                        );
                      }

                      if (error) return <p>Error :(</p>;

                      return (
                        <Table
                          className="client-listing-table"
                          dataSource={data.clientAccountsForFirm.items}
                          columns={clientListTable}
                          rowKey={(record) => record.client.id}
                          pagination={{ pageSize: 10 }}
                        />
                      );
                    }}
                  </Query>

                  {/* Add firm member modal */}
                  <Modal
                    title="Add firm member"
                    centered
                    destroyOnClose={true}
                    open={this.state.addFirmMemberModalVisible}
                    onCancel={this.addFirmMemberModalVisible}
                    footer={null}
                    width="500px"
                    maskClosable={false}
                    closeIcon={<IconModalClose />}
                  >
                    <AddFirmMemberForm
                      firmId={this.props.match.params.id}
                      firmMemberTypes={firmData.firmProfessionalTypes.enumValues}
                      firmMemberRoles={firmData.firmRoles.enumValues}
                      addFirmMemberModalVisible={this.addFirmMemberModalVisible}
                    />
                  </Modal>

                  {/* Update firm member modal */}
                  <Modal
                    title="Update firm member"
                    centered
                    destroyOnClose={true}
                    open={this.state.updateFirmMemberModalVisible}
                    onCancel={this.updateFirmMemberModalVisible}
                    footer={null}
                    width="500px"
                    maskClosable={false}
                    closeIcon={<IconModalClose />}
                  >
                    <UpdateFirmMemberForm
                      firmId={this.props.match.params.id}
                      firmMemberId={this.state.firmMemberToUpdate}
                      firmMemberTypes={firmData.firmProfessionalTypes.enumValues}
                      updateFirmMemberModalVisible={this.updateFirmMemberModalVisible}
                    />
                  </Modal>
                </>
              );
            } else {
              return <p>sorry, that firm doesn't seem to exist.</p>;
            }
          }}
        </Query>
      </>
    );
  }
}

export default FirmDetails;
