import React, { PureComponent } from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import { ApolloClient, ApolloProvider, InMemoryCache, ApolloLink, split } from '@apollo/client';
import { WebSocketLink } from "@apollo/client/link/ws";
import { Query, Subscription } from '@apollo/react-components';
import { IntrospectionFragmentMatcher } from 'apollo-cache-inmemory';
import { setContext } from "@apollo/client/link/context";
import { createUploadLink } from 'apollo-upload-client';
import { onError } from "@apollo/client/link/error";
import { getMainDefinition } from '@apollo/client/utilities';
import { BatchHttpLink } from "@apollo/client/link/batch-http";
import { Layout, Modal } from 'antd/es';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { SidebarLogo } from '../Logo/SidebarLogo';
import { MobileLogo } from '../Logo/MobileLogo';
import Menu from '../Menu';
import Loading from '../Loading';
import NotificationDrawer from '../NotificationDrawer';
import FirmListing from '../FirmListing';
import FirmDetails from '../FirmDetails';
import UserListing from '../UserListing';
import UserDetails from '../UserDetails';
import TaskListing from '../TaskListing';
import TaskDetails from '../TaskDetails';
import LoginPage from '../LoginPage';
import PageNotFound from '../PageNotFound';
import { ReactComponent as IconNotifications } from '../../assets/images/icon-notifications.svg';
import introspectionQueryResultData from '../../graphql/fragmentTypes.json';
import { BP_LARGE } from '../../consts';
import './App.scss';
import * as Sentry from '@sentry/browser';
import env from '@beam-australia/react-env';
import { NEW_NOTIFICATION } from '../../graphql/notifications/new-notification';
import { LIST_NOTIFICATIONS } from '../../graphql/notifications/list-notifications';

/* Apollo Client configuration */
const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData,
});

const authLink = setContext(async (_, { headers }) => {
  // return the headers to the context so httpLink can read them
  const token = await localStorage.getItem('authToken');

  return {
    headers: {
      ...headers,
      accept: 'application/json',
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

// If user is unauthenticated because their token has expired, log them out
let logoutGlobal;
const errorLink = onError((error) => {
  if (error?.networkError || error?.graphQLErrors) {
    if (
      (error?.networkError?.message === 'Unauthenticated' ||
        !!error?.graphQLErrors?.find(
          (er) => er?.message === 'Unauthorized' || er?.message === 'Unauthenticated'
        )) &&
      logoutGlobal
    ) {
      logoutGlobal(true);
    }
  }
});

const customFetch = (_, options) => {
  // Check if request is file upload
  if (options.body instanceof FormData) return fetch(env('API_URL'), options);
  return fetch(env('API_URL'), options);
};

const definitionLink = ({ query }) => {
  const definition = getMainDefinition(query);
  return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
};

const isObject = (node) => typeof node === 'object' && node !== null;

// Rough first draft, could probably be optimised in a loads of different ways.
const hasFiles = (node, found = []) => {
  Object.keys(node).forEach((key) => {
    if (!isObject(node[key]) || found.length > 0) {
      return;
    }

    if (
      (typeof File !== 'undefined' && node[key] instanceof File) ||
      (typeof Blob !== 'undefined' && node[key] instanceof Blob)
    ) {
      found.push(node[key]);
      return;
    }

    hasFiles(node[key], found);
  });

  return found.length > 0;
};

const wsLink = new WebSocketLink({
  uri: env('WS_URL'),
  options: {
    lazy: true,
    reconnect: true,
    connectionParams: async () => {
      const token = await localStorage.getItem('authToken');

      return {
        authToken: token,
      };
    },
  },
});

const uploadLink = split(
  ({ variables }) => hasFiles(variables),
  createUploadLink({
    uri: env('API_URL'),
    fetch: customFetch,
  }),
  new BatchHttpLink({
    uri: env('API_URL'),
    fetch: customFetch,
  })
);

const client = new ApolloClient({
  link: ApolloLink.from([errorLink, authLink, split(definitionLink, wsLink, uploadLink)]),
  cache: new InMemoryCache({
    fragmentMatcher,
  }),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'cache-and-network',
    },
    query: {
      fetchPolicy: 'cache-and-network',
    },
  },
});

class App extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      loggedIn: false,
      mobileMenuActive: false,
      notificationDrawerVisible: false,
    };

    logoutGlobal = this.handleLogout;
  }

  componentDidMount() {
    // If access token exists then log user in (should user refresh the page or open a new window)
    if (localStorage.getItem('authToken')) {
      this.setState({
        loggedIn: true,
      });
    }

    // If window size is over a certain width then hide the mobile menu
    window.addEventListener('resize', () => {
      if (window.innerWidth >= BP_LARGE) {
        this.setState({ mobileMenuActive: false });
      }
    });
  }

  handleLogin = (data) => {
    const { user, accessToken } = data.loginWithPassword;
    this.setState({ loggedIn: true, mobileMenuActive: false });
    localStorage.setItem('authToken', accessToken);
    Sentry.setUser({ id: user.id, email: user.email, name: user.fullName });
  };

  handleLogout = (forceLogout) => {
    if (forceLogout) {
      this.setState({ loggedIn: false });
      client.resetStore();
      localStorage.removeItem('authToken');
      Sentry.configureScope((scope) => scope.clear());
    } else {
      Modal.confirm({
        title: 'Logout',
        icon: <ExclamationCircleOutlined />,
        content: `Are you sure you want logout?`,
        centered: true,
        okText: 'Logout',
        className: 'logout-modal',
        cancelText: 'Cancel',
        onOk: () => {
          this.setState({ loggedIn: false });
          client.resetStore();
          localStorage.removeItem('authToken');
          Sentry.configureScope((scope) => scope.clear());
        },
      });
    }
  };

  handleMobileMenuClick = () => {
    if (window.innerWidth < BP_LARGE) {
      this.setState((prevState) => {
        return { mobileMenuActive: !prevState.mobileMenuActive };
      });
    }
  };

  handleNotificationDrawerVisible = () => {
    this.setState((prevState) => {
      return { notificationDrawerVisible: !prevState.notificationDrawerVisible };
    });
  };

  hasUnreadNotifications = () => {
    this.setState({
      hasUnreadNotifications: true,
    });
  };

  render() {
    const { Header, Content, Sider } = Layout;

    return (
      <ApolloProvider client={client}>
        <BrowserRouter
          getUserConfirmation={(message, callback) => {
            Modal.confirm({
              title: 'Unsaved changes',
              icon: <ExclamationCircleOutlined />,
              content: message,
              centered: true,
              okText: 'Yes, leave page',
              cancelText: 'No, go back',
              onOk: () => callback(true),
              onCancel: () => callback(false),
            });
          }}
        >
          {this.state.loggedIn ? (
            <Query
              query={LIST_NOTIFICATIONS}
              variables={{
                page: 1,
              }}
            >
              {({ loading, data: notificationsData }) => {
                if (loading) {
                  return <Loading />;
                }

                return (
                  <>
                    <Subscription
                      subscription={NEW_NOTIFICATION}
                      variables={{}}
                      onSubscriptionData={({ client, subscriptionData }) => {
                        const query = client.readQuery({
                          query: LIST_NOTIFICATIONS,
                          variables: {
                            page: 1,
                          },
                        });

                        const notifications = [
                          subscriptionData.data.newNotification,
                          ...query.notifications.notifications,
                        ];

                        const notificationsDeDuped = Array.from(
                          new Set(notifications.map((a) => a.id))
                        ).map((id) => {
                          return notifications.find((a) => a.id === id);
                        });

                        const notificationsData = {
                          notifications: {
                            ...query.notifications,
                            totalUnread: query.notifications.totalUnread + 1,
                            notifications: notificationsDeDuped,
                          },
                        };

                        client.writeQuery({
                          query: LIST_NOTIFICATIONS,
                          data: notificationsData,
                          variables: {
                            page: 1,
                          },
                        });
                      }}
                    >
                      {({ data: newNotificationData }) => {
                        return (
                          <>
                            <NotificationDrawer
                              open={this.state.notificationDrawerVisible}
                              onClose={this.handleNotificationDrawerVisible}
                              notifications={notificationsData.notifications.notifications}
                              hasMorePages={notificationsData.notifications.hasMorePages}
                              totalUnread={notificationsData.notifications.totalUnread}
                            />
                            <Layout>
                              <Sider
                                className={`${this.state.mobileMenuActive && 'mobile-menu-active'
                                  } menu-sidebar`}
                                id="menu-sidebar"
                              >
                                <SidebarLogo />
                                <Menu
                                  handleLogout={this.handleLogout}
                                  handleMobileMenuClick={this.handleMobileMenuClick}
                                  handleNotificationDrawerVisible={
                                    this.handleNotificationDrawerVisible
                                  }
                                  totalUnreadNotifications={
                                    notificationsData.notifications.totalUnread
                                  }
                                />
                              </Sider>

                              <Layout
                                className={`site-layout ${this.state.mobileMenuActive && 'mobile-menu-active'
                                  }`}
                              > 
                                <Header className="site-layout-background site-header">
                                  <MobileLogo />
                                  <div
                                    className={`site-header__notification ${notificationsData.notifications.totalUnread > 0
                                        ? 'site-header__notification--unread'
                                        : ''
                                      }`}
                                    onClick={this.handleNotificationDrawerVisible}
                                  >
                                    <IconNotifications />
                                  </div>
                                  <div
                                    className={`burger ${this.state.mobileMenuActive ? 'active' : 'inactive'
                                      }`}
                                    onClick={this.handleMobileMenuClick}
                                  >
                                    <span></span>
                                  </div>
                                </Header>
                                <Content>
                                  <Switch>
                                    <Route path="/firms/:id" component={FirmDetails} />
                                    <Route path="/firms" component={FirmListing} />
                                    <Route path="/users/:id" component={UserDetails} />
                                    <Route path="/users" component={UserListing} />
                                    <Route path="/tasks/:id" component={TaskDetails} />
                                    <Route path="/tasks" component={TaskListing} />
                                    <Route exact path="/" component={FirmListing} />
                                    <Redirect to="/" from="/login" />
                                    <Route path="*" component={PageNotFound} />
                                  </Switch>
                                </Content>
                              </Layout>
                            </Layout>
                          </>
                        );
                      }}
                    </Subscription>
                  </>
                );
              }}
            </Query>
          ) : (
            <Switch>
              <Route path={['/login', '*']}>
                <LoginPage handleLogin={this.handleLogin} />
              </Route>
            </Switch>
          )}
        </BrowserRouter>
      </ApolloProvider>
    );
  }
}

export default App;
